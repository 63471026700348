<template>
    <div class="news-list">
        <Bread :breads="breads"></Bread>
        <div class="news-nav">
            <div class="news-nav-list">
                <template v-for="(item, index) in categories">
                    <div class="news-nav-item" :class="{'active':_active==index}"  @click="navChange(index)"  v-if="item.name != '最新发布'">
                        {{item.name}}
                    </div>
                </template>
                <slot name="menu">
                </slot>
            </div>
            <div class="news-content">
                <slot name="content">

                </slot>
            </div>
        </div>
      
    </div>
</template>

<script>
import Bread from './Bread'
export default {
    data(){
        return {
            _active: this.active
        }
    },
    components:{Bread},
    props:{
       breads:{
           type: Array,
           default: ''
       },
        active:{
            type: Number,
            default: 1
        },
        categories:{
            type: Array, 
            default: []
        }
    },
    watch:{
        active(newVal, oldVal){
            this._active = newVal;
        },
        categories(newVal, oldVal){
            // console.log(newVal)
        }
    },
    mounted(){
    },
    methods:{
        navChange: function(index){
            this._active = index;
            this.$emit('itemClick', index);
        }
    }
}
</script>

<style lang="less" scoped>

.news-content{

    width: 883px;
}
.news-nav{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.news-nav-list{
    display: inline-block;
    width: 283px;

    .news-nav-item{
        background: #F2F2F2;
        font-family: SourceHanSansCN-Regular;
        font-size: 18px;
        color: #242424;
        letter-spacing: 0;
        text-align: center;
        line-height: 48px;
        margin-bottom: 11px;
        cursor: pointer;
    }
    .news-nav-item:hover{
        background: #C3c3c3;
    }
    .active,.active:hover{
        color: #FFFFFF;
        background: #007EEC;
    }
}
    .nav-item{
        width: 283px;
        margin-bottom: 11px;
        font-family: SourceHanSansCN-Regular;
        font-size: 18px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        line-height: 48px;
    }
</style>

